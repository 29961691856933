<template>
  <div>
    <loading v-if="Loading"/>
    <b-modal v-model="modalShow" title="Webhook URL" hide-footer size="lg">
      <validation-observer ref="DiscordWebhook" tag="form" @submit.prevent="submitAdd">
        <b-form  @submit.prevent="submitAdd">
          <loading v-if="Loading"/>
          <b-row v-if="!Loading">

            <b-col cols="12">
              <b-form-group label="Url">
                <validation-provider #default="{ errors }" name="New Webhook url" rules="required">
                  <b-form-input v-model="formWebhook.url" name="url" placeholder="New Webhook url" locale="id" :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" variant="primary" v-if="!Loading">Submit</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Tabel -->
    <b-row v-if="!Loading">
      <b-col class="col-12">
        <b-table striped hover :items="tableData" :fields="fields" :per-page="perPage" :current-page="currentPage">
          <template v-slot:cell(action)="row">
            <b-button @click="editWebhook(row.item)" variant="primary" size="sm">Edit</b-button>&nbsp;
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="tableData.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios,
    quillEditor
  },
  data() {
    return {
      Loading: false,
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      fields: [
        {key:"name",text:"name"},
        {key:"url",text:"url"},
        {key:"action",text:"Action"}
      ],
      modalShow: false,
      formWebhook : _interfaces.discord_webhook,
      tableData: [],
    };
  },
  methods: {
    init(){
      this.getData();
    },
    showModal() {
      this.modalShow = true;
    },
    getData() {
      axios.post("idp/master/dcwebhook/list",{id:null}).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    submitAdd() {
      this.$refs.DiscordWebhook.validate().then((success) => {
        if(success){ 
          this.Loading = true;          
          setTimeout(() => {
              axios.post("idp/master/dcwebhook/edit",this.formWebhook).then((response) => {
              }).catch((error) => {
                if (error.status === 4000) {
                  localStorage.clear();
                  this.$router.push({ path: "/login" });
                  ({
                    title: "Login Expired",
                    message: "Sesi Login Habis",
                  });
                }
              }).finally(() => {
                this.Loading = false;
                this.modalShow = false;
                this.init();
              });
            }, 1000);
        }
      });
    },
    editWebhook(row){
      this.formWebhook.url = null;
      
      this.isEdit = true;
      this.modalShow = true;
      this.formWebhook.id = row.id
      this.content = row.content
    },
  },
  mounted(){
    this.init();
  }
}
</script>